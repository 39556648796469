import { ContainerInject, Whitelist, WhitelistProvider } from 'leanplum-lib-common';
import Vue, { VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import './ErrorModal.scss';

@Component({ name: 'ErrorModal' })
class ErrorModal extends Vue {
  static readonly DEFAULT_TIMEOUT_INTERVAL_MS: number = 5000;
  static readonly DEFAULT_TITLE = 'Something went wrong... 😞';
  static readonly DEFAULT_TEXT =
    "We're terribly sorry about that. If the page is not responding, please refresh the page.";

  @ContainerInject(WhitelistProvider)
  private readonly whitelistProvider: WhitelistProvider;

  @Prop({ required: true })
  showError: boolean;

  @Prop({ required: false, default: ErrorModal.DEFAULT_TITLE })
  title: string;

  @Prop({ required: false, default: ErrorModal.DEFAULT_TEXT })
  text: string;

  @Prop({ required: false, default: false })
  autoClose: boolean;

  @Watch('showError')
  protected onShowErrorChange(): void {
    if (this.shouldHideUnknownErrors) {
      this.showError = false;

      return;
    }

    if (this.autoClose) {
      setTimeout(() => {
        this.$emit('close');
      }, ErrorModal.DEFAULT_TIMEOUT_INTERVAL_MS);
    }
  }

  render(): VNode {
    return (
      <div class="error-container">
        <transition>
          {this.showError && (
            <div class="error">
              <div class="close" onClick={() => this.$emit('close')}>
                ×
              </div>
              <div class="content">
                <p class="title">{this.title}</p>
                <p class="text">{this.text}</p>
              </div>
            </div>
          )}
        </transition>
      </div>
    );
  }

  private get shouldHideUnknownErrors(): boolean {
    return this.whitelistProvider.isWhitelistedFor(Whitelist.HIDE_UNKNOWN_ERRORS);
  }
}

export { ErrorModal };
