import { ContainerInject, Throttled, Whitelist, WhitelistProvider } from 'leanplum-lib-common';
import values from 'lodash/values';
import Vue, { VNode } from 'vue';
import { Component, Prop, ProvideReactive, Watch } from 'vue-property-decorator';

import './DevicePreview.scss';

@Component({ name: 'DevicePreview' })
class DevicePreview extends Vue {
  static readonly EVENT_CHANGE_SCALE = 'changeScale';
  static HEIGHT: number = 800;
  static WIDTH: number = 400;
  static DEFAULT_OUTLINE_COLOR: string = '#1076fb';
  static CONTAINER_PADDING: number = 80;

  private resizeObserver: ResizeObserver | null = null;

  @ContainerInject(WhitelistProvider)
  private readonly whitelistProvider: WhitelistProvider;

  @ProvideReactive()
  public scale: number = 1;

  @Prop({
    type: String,
    required: false,
    default: 'portrait',
    validator(value: DevicePreview.OrientationType): boolean {
      return values(DevicePreview.OrientationType).indexOf(value) > -1;
    }
  })
  readonly orientation: DevicePreview.OrientationType;

  @Prop({ type: Number, required: false, default: DevicePreview.WIDTH })
  readonly width: number;

  @Prop({ type: Number, required: false, default: DevicePreview.HEIGHT })
  readonly height: number;

  @Prop({ type: Boolean, required: false, default: false })
  readonly highlighted: boolean;

  @Prop({ type: String, required: false, default: DevicePreview.DEFAULT_OUTLINE_COLOR })
  readonly highlightColor: string;

  @Prop({ type: Number, required: false, default: DevicePreview.CONTAINER_PADDING })
  readonly containerPadding: number;

  @Prop({ type: Boolean, required: false, default: false })
  readonly overflowHidden: boolean;

  // TODO: (Zlatko) This is a workaround solution to handle
  // TODO: the device position in MMC full-preview screen
  // TODO: Need to another solution and remove the prop!
  @Prop({ type: String, required: false, default: '-50%' })
  readonly translateY: string;

  $refs!: {
    scaleContainer: HTMLDivElement;
  };

  @Watch('width', { immediate: true })
  @Watch('height', { immediate: true })
  @Watch('orientation', { immediate: true })
  protected changeDimensions(): void {
    this.onResize();
  }

  mounted(): void {
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.scaleContainer);

    // Reset to the initial value, used in the previous implementation.
    if (!this.isWhitelistedForAltImpl) {
      this.scale = 0;
    }
  }

  beforeDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  render(): VNode {
    return (
      <div ref="scaleContainer" class="device-scale-wrapper">
        <div class="device-preview-frame" style={this.contentStyle}>
          {this.$slots.default}
        </div>
      </div>
    );
  }

  private get contentStyle(): Partial<CSSStyleDeclaration> {
    const scale = this.isWhitelistedForAltImpl ? '' : `scale(${this.scale || 1})`;

    return {
      borderRadius: this.isWhitelistedForAltImpl ? `${62 * this.scale}px` : undefined,
      width: this.deviceWidth,
      height: this.deviceHeight,
      outline: this.highlighted ? `0.75rem solid ${this.highlightColor}` : 'none',
      transform: [`translate(-50%, ${this.translateY})`, scale].join(' '),
      overflow: this.overflowHidden ? 'hidden' : 'unset',
      visibility: this.isWhitelistedForAltImpl ? undefined : (this.scale ? 'visible' : 'hidden')
    };
  }

  private get deviceDimensionX(): number {
    return this.orientation === 'landscape' ? this.height : this.width;
  }

  private get deviceDimensionY(): number {
    return this.orientation === 'landscape' ? this.width : this.height;
  }

  private get deviceHeight(): string {
    const scale = this.isWhitelistedForAltImpl ? this.scale : 1;

    return `${this.deviceDimensionY * scale}px`;
  }

  private get deviceWidth(): string {
    const scale = this.isWhitelistedForAltImpl ? this.scale : 1;

    return `${this.deviceDimensionX * scale}px`;
  }

  private get isWhitelistedForAltImpl(): boolean {
    return this.whitelistProvider.isWhitelistedFor(Whitelist.ALTERNATIVE_IMAGE_IAM_IMPL);
  }

  @Throttled()
  private onResize(): void {
    if (this.$refs.scaleContainer) {
      const containerWidth = this.$refs.scaleContainer.clientWidth - this.containerPadding;
      const containerHeight = this.$refs.scaleContainer.clientHeight - this.containerPadding;
      this.scale = Math.min(containerWidth / this.deviceDimensionX, containerHeight / this.deviceDimensionY, 1);
      this.$emit(DevicePreview.EVENT_CHANGE_SCALE, this.scale);
    }
  }
}

namespace DevicePreview {
  export enum OrientationType {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape'
  }

  export interface Props {
    orientation?: DevicePreview.OrientationType;
    width?: number;
    height?: number;
    highlighted?: boolean;
    highlightColor?: string;
    overflowHidden?: boolean;
  }
}

export { DevicePreview };
